import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1,Slogan,SubTitle } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"
import ContBlock from "../components/contBlock"


export const pageQuery = graphql`
 query {
    allStrapiServices{
        edges {
         node {
           seo {
            metaTitle
            metaDescription
            shareImage {
                url
            }
           }
           description_blocks {
               title
               description
           }
           how {
                description
                title
                media {
                    localFile {
                        childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
                link {
                    url
                }
            }
           industries {
               title
               description
               link {
                   url
               }
           }
           capabilities {
               title
               tooltip
               image {
                localFile {
                    childImageSharp {
                    gatsbyImageData(width: 640, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
               }
               link {
                    url
                }
           }
         }
       }
      }
 }`

export default function IndexPage({location,data}) {
    const node = data.allStrapiServices.edges[0].node
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                <h1 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pt-[260px] pb-[95px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.description_blocks[0].title}</h1>
                <Slogan data-aos="fade-down" data-aos-duration="600" className="com-indent">{node.description_blocks[0].description}</Slogan>
                <div className="flex items-start lg:flex-col">
                    <h2 className="text-[56px] xl:text-[40px] leading-tight w-1/4 sticky top-0 pt-[180px] lg:pt-0 pr-8 lg:mb-16 lg:static lg:w-full lg:pr-0">How it’s done</h2>
                    <div className="w-3/4 lg:w-full">
                        {
                            node.how.map((n,i)=>{
                                return (
                                    <div onClick={()=>{window.location.href=n.link.url}} className={`flex items-center group cursor-pointer md:flex-col ${i%2!==0?'flex-row-reverse':''} mb-[120px]`} key={i}>
                                        <figure data-aos="fade-up" data-aos-duration="600" className={`w-[55.555556%] md:w-full md:mb-10 ${i%2!==0?'pl-[50px] md:pl-0':'pr-[50px] md:pr-0'}`}><GatsbyImage className="w-full" image={getImage(n.media.localFile)} alt="banner"/></figure>
                                        <div className="w-[44.444444%] md:w-full">
                                            <SubTitle className="pb-8">{n.title}</SubTitle>
                                            <p className="pb-8 text-xl">{n.description}</p>
                                            <A href={n.link.url} variant="more">Learn More</A>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <h2 data-aos="fade-up" data-aos-offset="300" data-aos-duration="600" className="text-[200px] leading-none pt-[300px] lg:pt-0 pb-[95px] xl:text-[150px] lg:text-[72px]">{node.description_blocks[1].title}</h2>
                <Slogan data-aos="fade-up" data-aos-duration="600" className="com-indent">{node.description_blocks[1].description}</Slogan>
                <div className="grid grid-cols-12 lg:grid-cols-1">
                    <h3 className="col-span-3 text-[56px] xl:text-[40px] sticky top-[180px] leading-none pr-8 h-fit lg:static lg:mb-16 lg:pr-0">Industries</h3>
                    <div className="grid col-span-9 grid-cols-3 gap-x-8 gap-y-[100px] lg:grid-cols-2 md:grid-cols-1">
                        {
                            node.industries.map((n,i)=>{
                                return (
                                    <div data-aos="fade-up" data-aos-duration={600+i*400} key={i}>
                                        <h4 className="text-[32px] leading-snug pb-4">{n.title}</h4>
                                        <p className="text-xl pb-8">{n.description}</p>
                                        <A variant="border-gray" href={n.link.url} className="text-xl">Read more</A>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <h2 data-aos="fade-up" data-aos-offset="300" data-aos-duration="600" className="text-[200px] leading-none pt-[300px] lg:pt-[120px] pb-[95px] xl:text-[150px] lg:text-[72px]">{node.description_blocks[2].title}</h2>
                <Slogan data-aos="fade-up" data-aos-duration="600" className="com-indent">{node.description_blocks[2].description}</Slogan>
                <ContBlock lg={true} title="Capabilities" cont={
                    <div className="grid grid-cols-3 gap-x-8 gap-y-[100px] lg:grid-cols-2 md:grid-cols-1">
                        {
                            node.capabilities.map((n,i)=>{
                                return <Group imgH='!pt-[57.6%]' data-aos-duration={600+i*400} lg={true} key={i} node={n} />
                            })
                        }
                    </div>
                }/>
            </div>
        </Layout>
    )
}

