/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from 'react';

const Layout = (props) => {
  const {title,cont,lg} = props

  return (
    <div className="grid grid-cols-12 lg:grid-cols-1">
       <h2 className="col-span-3 text-[56px] xl:text-[40px] sticky h-fit top-[180px] leading-tight lg:static lg:mb-16 lg:col-span-1">{title}</h2>
       <div className={`${lg?'col-span-9':'col-span-6'} lg:col-span-1`}>{cont}</div>
    </div>
  )
}


export default Layout
